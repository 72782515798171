import React from "react";
import _ from "lodash";
import { Switch, Route, Redirect } from "react-router-dom";

import SnapshotViewer from "./components/snapshot/SnapshotViewer";
import YieldSetSnapshotViewer from "./components/snapshot/YieldSetSnapshotViewer";
import ObserverViewer from "./components/observer/ObserverViewer";
import SnapshotDetail from "./components/snapshot/SnapshotDetail";
import UnitInsightViewer from "./components/unit-insight/UnitInsightViewer";
import ControlCenterViewer from "./components/control-center/ControlCenterViewer";
import BizViewer from "./components/biz/BizViewer";
import ReportingStatusViewer from "./components/healthcheck/ReportingStatusViewer";
import GptRequestsComparison from "./components/tools/GptRequestsComparison";
import HomeDashboardViewer from "./components/playground/home-dashboard/HomeDashboardViewer";
import BatchUpdateAPConfigs from "./components/tools/BatchUpdateAPConfigs";
import YbConsoleOpsLogViewer from "./components/console-ops-log/YbConsoleOpsLogViewer";

function MainRouter() {
  return (
    <>
      <div className="relative h-screen">
        <Switch>
          <Route
            path="/console/ops-log"
            component={YbConsoleOpsLogViewer}
          ></Route>

          <Route
            path="/playground/console-overview-mockup/:pubId"
            component={HomeDashboardViewer}
          ></Route>

          <Route
            path="/playground/console-overview-mockup-reseller/:pubId"
            component={HomeDashboardViewer}
          ></Route>

          {/* TOOLS */}
          <Route
            path="/tools/batch-update-auto-pilot-config"
            component={BatchUpdateAPConfigs}
          ></Route>

          <Route
            path="/tools/gpt-requests-comparison"
            component={GptRequestsComparison}
          ></Route>

          <Route path="/control-center" component={ControlCenterViewer}></Route>

          <Route
            path="/healthcheck/reporting-status"
            component={ReportingStatusViewer}
          ></Route>

          <Route path="/biz/overview" component={BizViewer}></Route>
          <Route path="/biz/network/:networkId" component={BizViewer}></Route>

          <Route
            exact
            path="/unit-insight/:unitId"
            component={UnitInsightViewer}
          ></Route>

          <Route
            exact
            path="/network/:networkId/yield-set-snapshot"
            component={YieldSetSnapshotViewer}
          ></Route>

          <Route
            exact
            path="/snapshot/:snapshotKey/detail/:detailKey"
            component={SnapshotDetail}
          ></Route>

          <Route
            exact
            path="/snapshot/:snapshotKey"
            component={SnapshotViewer}
          ></Route>

          <Route
            path="/observer/:unitId"
            render={(props) => {
              const unitId = _.get(props, "match.params.unitId");
              return (
                <ObserverViewer key={unitId} unitId={unitId}></ObserverViewer>
              );
            }}
          ></Route>

          <Route
            exact
            path="/"
            render={() => <Redirect to="/snapshot/latest"></Redirect>}
          ></Route>
        </Switch>
      </div>
    </>
  );
}

export default MainRouter;
