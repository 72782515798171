import _ from "lodash";
import { transformObserverData } from "./Observer";
import { transformSnapshotData } from "./Snapshot";
import { transformUnitInsightReports } from "./Unit-Insight";
import pako from "pako";
// import sd from "../sample-data/snapshot-2020-12-01_00_45.json";
// import sd from "../sample-data/2020-11-30_23_45.json";
// import sd from "../sample-data/2020-12-01_03_45.json";
// import osd from "../sample-data/test-obserser-dev-sample.json";
// import hdReports from "../sample-data/home_dashboard.json";

const api = process.env.REACT_APP_OBSERVER_API_ENDPOINT;
// const api = "http://emily.yieldbooster.io:9000";
// const api = "https://observer-dev.yieldbooster.io";
// const api = "https://observer-prod.yieldbooster.io";

export {
  getQuicksightDashboardUrl,
  getSnapshotDetail,
  getSnapshot,
  getYieldSetSnapshotUrl,
  getYieldSetSnapshot,
  getLatestObserverData,
  getObserverData,
  getUnitInsights,
  getNetworkProfileUrl,
  updateNetworkDailyGoal,
  queryNetworkDailyGoals,
  getNetworkDailyGoalHistory,
  // auto pilot
  getAutoPilotConfig,
  updateAutoPilotConfig,
  updateAutoPilotUserHints,
  forceIterateAutoPilotUnit,
  // biz
  getBizOverview,
  getBizByNetwork,
  // network reporting status
  getNetworkReportingStatuses,
  // batch update ap config
  getUnitInfos,
  batchExecuteApConfig,
  // mock home dashboard
  getHomeDashboardData,
  // yb console operation logs
  getConsoleOperationLogs,
  getPublishers,
};

async function getPublishers() {
  try {
    let apiUrl = `${api}/publishers`;

    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getConsoleOperationLogs({ pubIds, st, et, limit = 50, cursor }) {
  try {
    let apiUrl = `${api}/operation-logs`;

    let queryParams = [];
    if (limit) {
      queryParams.push("limit=" + limit);
    }
    if (cursor) {
      queryParams.push("cursor=" + encodeURIComponent(cursor));
    }

    if (pubIds) {
      queryParams.push("pubIds=" + pubIds);
    }

    if (st) {
      queryParams.push("st=" + encodeURIComponent(st));
    }

    if (et) {
      queryParams.push("et=" + encodeURIComponent(et));
    }

    if (queryParams.length > 0) {
      apiUrl = apiUrl + "?" + queryParams.join("&");
    }

    return await fetchRespJson(apiUrl);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getHomeDashboardData(pubId) {
  try {
    // const data = _.cloneDeep(hdReports);
    const apiUrl = `${api}/get-home-dashboard-url?pubId=${pubId}`;
    const { url } = await fetchRespJson(apiUrl);
    console.log(url);
    const data = await fetchRespJson(url);
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getUnitInfos({ unitIds }) {
  const apiUrl = `${api}/unit/infos`;
  let updateParams = {
    unitIds,
  };
  return await postData(apiUrl, updateParams);
}

async function batchExecuteApConfig({
  unitIds,
  operations,
  targetings,
  shouldForceIterate,
}) {
  const apiUrl = `${api}/autopilot/config/batch-operation`;
  let updateParams = {
    unitIds,
    operations,
    targetings,
    shouldForceIterate,
  };
  return await postData(apiUrl, updateParams);
}

async function getYieldSetSnapshotUrl({ networkId }) {
  const apiUrl = `${api}/network/${networkId}/yield-set-snapshot`;
  const { signedUrl, range, reportTime } = await fetchRespJson(apiUrl);
  return await getYieldSetSnapshot({
    signedUrl,
    range,
    reportTime,
  });
}

async function getYieldSetSnapshot({ signedUrl, range, reportTime }) {
  try {
    const options = {
      headers: {
        range: `bytes=${range.start}-${range.end}`,
      },
    };
    const response = await fetch(signedUrl, options);
    const result = await response.arrayBuffer();

    const p = pako.inflate(result, { to: "string" });

    // Sample link
    // const link =
    //   "https://s3-ap-northeast-1.amazonaws.com/yb-observer-results-dev/snapshot-sample.json";

    // const snapshots = _.cloneDeep(osd.networkSnapshots);
    // const snapshots = require(link);
    // const data = await fetchRespJson(link);

    return {
      reportCreateTime: reportTime,
      data: transformSnapshotData(JSON.parse(p)),
      // data,
    };
  } catch (err) {
    console.log("error get data", err);
    throw err;
  }
}

async function getNetworkReportingStatuses() {
  const apiUrl = `${api}/healthcheck/network-report-status`;
  return await fetchRespJson(apiUrl);
}

async function getBizByNetwork({ startDate, endDate, networkId }) {
  if (!startDate || !networkId) {
    throw new Error("startDate and networkId are required");
  }

  const dateRangeQueryStr = `?startDate=${startDate}${
    endDate ? `&endDate=${endDate}` : ""
  }`;
  const apiUrl = `${api}/biz/network/${networkId}${dateRangeQueryStr}`;
  return await fetchRespJson(apiUrl);
}

async function getBizOverview({ startDate, endDate }) {
  if (!startDate) {
    throw new Error("startDate is required");
  }

  const dateRangeQueryStr = `?startDate=${startDate}${
    endDate ? `&endDate=${endDate}` : ""
  }`;
  const apiUrl = `${api}/biz/overview/v2${dateRangeQueryStr}`;
  const { link } = await fetchRespJson(apiUrl);
  const data = await fetchRespJson(link);
  return data;
}

async function forceIterateAutoPilotUnit({ unitId }) {
  const apiUrl = `${api}/unit/${unitId}/autopilot/command`;
  let updateParams = {
    unitId,
    commandOptions: {
      shouldForceIterate: true,
    },
  };
  return await postData(apiUrl, updateParams);
}

async function updateAutoPilotUserHints({ unitId, userHints }) {
  const apiUrl = `${api}/unit/${unitId}/autopilot/user-hints`;
  let updateParams = {
    unitId,
    userHints,
  };
  return await postData(apiUrl, updateParams);
}

async function updateAutoPilotConfig({
  networkId,
  unitId,
  config,
  commandOptions,
}) {
  if (unitId) {
    const apiUrl = `${api}/unit/${unitId}/autopilot/config`;
    let updateParams = {
      unitId,
      config,
      commandOptions,
    };
    return await postData(apiUrl, updateParams);
  }

  if (networkId) {
    const apiUrl = `${api}/network/${networkId}/autopilot/config`;
    let updateParams = {
      networkId,
      config,
    };
    return await postData(apiUrl, updateParams);
  }
}

async function getAutoPilotConfig({ unitId, networkId }) {
  // /unit/689/autopilot/config
  // /network/15/autopilot/config

  if (unitId) {
    const apiUrl = `${api}/unit/${unitId}/autopilot/config`;
    const resultData = await fetchRespJson(apiUrl);

    // // Important! special case! Added extra outer layer of array
    // transform config...
    const c = resultData.config;
    const dc = resultData.defaultConfig;
    if (
      _.has(c, "prophet.benchmark_abuse_sizes") &&
      c.prophet.benchmark_abuse_sizes.length > 0
    ) {
      c.prophet.benchmark_abuse_sizes = _.first(
        c.prophet.benchmark_abuse_sizes
      );
    }

    if (
      _.has(dc, "prophet.benchmark_abuse_sizes") &&
      dc.prophet.benchmark_abuse_sizes.length > 0
    ) {
      dc.prophet.benchmark_abuse_sizes = _.first(
        dc.prophet.benchmark_abuse_sizes
      );
    }

    resultData.config = c;
    resultData.defaultConfig = dc;

    return resultData;
  }

  if (networkId) {
    const apiUrl = `${api}/network/${networkId}/autopilot/config`;
    const resultData = await fetchRespJson(apiUrl);

    // // Important! special case! Added extra outer layer of array
    // transform config...
    const c = resultData.config;
    const dc = resultData.defaultConfig;
    if (
      _.has(c, "prophet.benchmark_abuse_sizes") &&
      c.prophet.benchmark_abuse_sizes.length > 0
    ) {
      c.prophet.benchmark_abuse_sizes = _.first(
        c.prophet.benchmark_abuse_sizes
      );
    }

    if (
      _.has(dc, "prophet.benchmark_abuse_sizes") &&
      dc.prophet.benchmark_abuse_sizes.length > 0
    ) {
      dc.prophet.benchmark_abuse_sizes = _.first(
        dc.prophet.benchmark_abuse_sizes
      );
    }

    resultData.config = c;
    resultData.defaultConfig = dc;

    return resultData;
  }
}

async function getNetworkDailyGoalHistory({ networkId }) {
  if (!networkId) {
    throw new Error("Missing networkId");
  }

  const apiUrl = `${api}/get-network-daily-goal-history?networkId=${networkId}`;
  const resultData = await fetchRespJson(apiUrl);
  const { history } = resultData;
  return history;
}

async function queryNetworkDailyGoals({ networkIds }) {
  const ids = networkIds.join(",");
  const apiUrl = `${api}/query-network-daily-goal?networkIds=${ids}`;
  const resultData = await fetchRespJson(apiUrl);
  const { data } = resultData;
  return data;
}

async function updateNetworkDailyGoal({ networkId, ntdGoal, comment }) {
  const apiUrl = `${api}/update-network-daily-goal`;
  let updateParams = {
    networkId,
    ntdGoal,
    comment: comment || "",
  };
  return await postData(apiUrl, updateParams);
}

async function getNetworkProfileUrl({ networkId }) {
  const apiUrl = `${api}/get-network-profile-url?networkId=${networkId}`;
  const resultData = await fetchRespJson(apiUrl);
  const { url } = resultData;

  // const url =
  //   "https://docs.google.com/document/d/1PK2dBMKtbfr8EXhHS3N95cwW4URbLYCQyje5ZM97KP8/edit";
  return url;
}

async function getUnitInsights(unitId, dateRange = 7, isForceRefresh = false) {
  // const apiUrl = `https://s3-ap-northeast-1.amazonaws.com/yb-observer-results-dev/unit-insight-sample.json`;

  let apiUrl = `${api}/get-unit-insight/${unitId}?lookback=${dateRange}`;
  if (isForceRefresh) {
    apiUrl += "&isForceRefresh=true";
  }
  const resultData = await fetchRespJson(apiUrl);

  return transformUnitInsightReports(resultData);
}

let quicksightUrl = null;

async function getQuicksightDashboardUrl() {
  if (quicksightUrl) {
    return quicksightUrl;
  }

  // const url =
  //   "https://ap-northeast-1.quicksight.aws.amazon.com/embed/31385ff37d2245e4b7aaf4e50e7a88bc/dashboards/3271bb3b-a99e-49d6-8711-29fbb830181e?code=AYABeP8mSDex3V52T_h6V9aMW1QAAAABAAdhd3Mta21zAFBhcm46YXdzOmttczphcC1ub3J0aGVhc3QtMTozNjcwOTQ1NjE4OTQ6a2V5LzkyZDU3MjEzLTc0MjItNGNhOC1iYWZiLTg2MDFjNGZkODgyNwC4AQIBAHjg9117DpT8mWIIRgCt-vnJt3wJU6Wwt65bSjxmlbOP5QHxKazBmMlOsP4ptNvY7DzmAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQM9MASZezI00C_MgJaAgEQgDvk5Ikb-ErKu8JG8N6fjrL1keNQHqgjjB5FG4ImQ1Yjwvxm1OBoIsuNUizBCwUNjFucX168WDcipatlGAIAAAAADAAAEAAAAAAAAAAAAAAAAACC4lq7HsR_moZj-T31M9Ki_____wAAAAEAAAAAAAAAAAAAAAEAAADMpLU1MHV6U37lf-mH2HWWXOZNAzNNsyMKKkgDExoSSwSMk2173EZvShVmMHe0KggUhUOqBd0PO8YJpwcUyvM-3v0JQQJ6uup1tzvUpyL6wBgX2b1r6YMwvUZCMPcHHFW4V0aAzd8OlDpiqRnPQ3wmwLDKjDS49No8lzbmvdMQUmBSyRA6TsrtCU5jyHDeZPckSsb9pkA5xMAnXq_AZGfjsb_hKybCVy8EmjyDQeo78UYFphNTJp8aAo3oVYf_15ixMSSoQ63sPXDn5W6S1z1IEbrmDOCyepxNKKs0SA%3D%3D&identityprovider=quicksight&isauthcode=true";
  // return url;

  const resultUrl = `${api}/get-dashboard-url`;
  const data = await fetchRespJson(resultUrl);
  const { url } = data;

  // Save url temporarily
  quicksightUrl = url.substring(0, url.indexOf("?"));

  return url;
}

async function getSnapshotDetail(detailKey) {
  const apiUrl = `${api}/get-snapshot-detail/${detailKey}`;
  return await fetchRespJson(apiUrl);
}

async function getSnapshot(snapshotKey) {
  if (!snapshotKey) {
    return null;
  }

  let key = null;
  if (snapshotKey === "latest") {
    key = await getLatestSnapshotKey();
  } else {
    key = snapshotKey;
  }

  const apiUrl = `${api}/get-snapshot/${key}`;
  const data = await fetchRespJson(apiUrl);
  const link = data.link;

  // Sample link
  // const link =
  //   "https://s3-ap-northeast-1.amazonaws.com/yb-observer-results-dev/snapshot-sample.json";

  // const snapshots = _.cloneDeep(osd.networkSnapshots);
  // const snapshots = require(link);
  const snapshots = await fetchRespJson(link);
  const networkIds = _.map(snapshots, "gam_network_id");
  const dailyGoals = await queryNetworkDailyGoals({ networkIds });
  const dailyGoalMap = _.reduce(
    dailyGoals,
    (result, g) => {
      result[g.networkId] = g.ntdGoal;
      return result;
    },
    {}
  );

  const resultData = transformSnapshotData(snapshots, dailyGoalMap);
  // const resultData = transformSnapshotData(snapshots);

  return {
    snapshotKey: key,
    reportCreateTime: data.report_create_time,
    data: resultData,
    // data: [_.find(resultData, { gam_network_id: 40 })]
  };
}

async function getLatestObserverData(unitId, dateRange = 7) {
  const latestFile = await getLatestFileLink(unitId, { dateRange });
  const resultUrl = latestFile.link;

  // const resultUrl = `https://s3-ap-northeast-1.amazonaws.com/yb-observer-results-dev/observer-sample.json`;
  const resultData = await fetchRespJson(resultUrl);

  return transformObserverData(resultData);
}

async function getObserverData(resultKey) {
  if (!resultKey) {
    return null;
  }

  const filename = _.last(resultKey.split("/"));
  const reportCreateTime = _.drop(filename.split("-")).join("-");

  const resultUrl = await getFileLink(filename);
  const resultData = await fetchRespJson(resultUrl);

  return transformObserverData(resultData, reportCreateTime);
}

// local function
async function getLatestSnapshotKey() {
  const apiUrl = `${api}/get-latest-snapshot-key`;
  const data = await fetchRespJson(apiUrl);
  const key = _.get(data, "snapshotKey");
  return key;
}

// local function
async function getLatestFileLink(unitId, queryParams) {
  let queryString = "";
  if (queryParams) {
    const qs = _.map(queryParams, (value, key) => {
      return `${key}=${value}`;
    });
    queryString = `?${qs.join("&")}`;
  }
  const resultUrl = `${api}/get-latest-result-url/${unitId}${queryString}`;
  return await fetchRespJson(resultUrl);
}

// local function
async function getFileLink(filename) {
  const resultUrl = `${api}/get-result-url/${filename}`;
  const data = await fetchRespJson(resultUrl);
  const { link } = data;
  return link;
}

// local function
async function fetchRespJson(url) {
  try {
    const response = await fetch(url);
    const resultJson = await response.json();

    if (response.ok) {
      return resultJson; // parses JSON response into native JavaScript objects
    } else {
      throw JSON.stringify(resultJson);
    }
  } catch (err) {
    console.log("error get data", err);
    throw err;
  }
}

async function postData(url, data) {
  try {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST",
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    const resultJson = await response.json();

    if (response.ok) {
      return resultJson; // parses JSON response into native JavaScript objects
    } else {
      throw JSON.stringify(resultJson);
    }
  } catch (err) {
    console.log("error post data", err);
    throw err;
  }
}
