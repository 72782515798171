import React from "react";
import moment from "moment-timezone";
import UnitStatus from "../common/UnitStatus";
import UnitMode from "../common/UnitMode";
import { FiExternalLink } from "react-icons/fi";
import QsDashboardButton from "../snapshot/QsDashboardButton";
import SegmentModeIndicator from "../common/SegmentModeIndicator";
import PopupWrapper from "../common/PopupWrapper";
import SegmentBasicTable from "../common/SegmentBasicTable";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
// import FavoriteButton from "./FavoriteButton";

class UnitInsightHeader extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   isMouseOverFollowBtn: false,
    // };

    this.handleGoToObserver = this.handleGoToObserver.bind(this);
  }

  handleGoToObserver(unitId) {
    window.open(`${window.location.origin}/#/observer/${unitId}`);
  }

  render() {
    const { unit, segments, updatedAt } = this.props;

    const updateTime = moment(updatedAt).format("YYYY-MM-DD HH:mm");
    const updateTimeAgo = moment(updatedAt).fromNow();

    return (
      <>
        <div className="px-4 py-2 border-b">
          <div className="py-1 my-1">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <div className="text-blue-800 font-semibold">
                  <span className="text-blue-700 mr-1">
                    {unit.gamNetwork.gamNetworkId}
                  </span>
                  {unit.gamNetwork.name}
                </div>

                <div className="border-l ml-3 pl-3">
                  <div className="flex">
                    {/* <div className="mr-3">
                      <FavoriteButton isFavorite={true}></FavoriteButton>
                    </div> */}

                    <div className="flex flex-col">
                      <div className="flex text-lg leading-none">
                        <div className="mr-2">{unit.gamUnitId}</div>
                        <div className="font-black">
                          {unit.name}
                          {unit.deletedAt && (
                            <span className="mx-1 rounded uppercase bg-red-300 text-red-800 font-bold text-xs px-1">
                              Blacklist
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="flex text-xs mt-1 text-gray-600 leading-none">
                        <div className="mr-4 flex" title="Ext Unit ID">
                          {unit.extUnitId}
                          <ClickToCopyWrapper
                            copyText={unit.extUnitId}
                          ></ClickToCopyWrapper>
                        </div>

                        <div className="mr-4 flex" title="Ext Unit Path">
                          {unit.extUnitPath}
                          <ClickToCopyWrapper
                            copyText={unit.extUnitPath}
                          ></ClickToCopyWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <div className="mr-3">
                  <UnitStatus unit={unit} showName={true}></UnitStatus>
                </div>
                <UnitMode unit={unit} showName={true}></UnitMode>{" "}
                {/* <div className="ml-4">
                  <button
                    type="button"
                    className={`px-3 py-1 font-bold rounded border border-blue-600 text-sm ${
                      unit.isFollowing
                        ? "bg-blue-600 text-white hover:bg-red-600 hover:border-red-600"
                        : "text-blue-800 hover:bg-blue-600 hover:text-white hover:shadow"
                    }`}
                    onMouseOver={() =>
                      this.setState({ isMouseOverFollowBtn: true })
                    }
                    onMouseLeave={() =>
                      this.setState({ isMouseOverFollowBtn: false })
                    }
                  >
                    {unit.isFollowing
                      ? this.state.isMouseOverFollowBtn
                        ? "Unfollow"
                        : "Following"
                      : "Follow"}
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-2">
            <div className="w-1/3 overflow-x-auto text-sm">
              <span className="block uppercase text-xs text-gray-600 font-semibold leading-none">
                Onboarded Sizes (
                {unit.onboardedSizesArray ? unit.onboardedSizesArray.length : 0}
                )
              </span>
              {unit.onboardedSizesArray && unit.onboardedSizesArray.length > 0 && (
                <div className="overflow-x-auto pt-1 pb-2">
                  {unit.onboardedSizesArray.map((s, i) => (
                    <span
                      key={i}
                      className="font-mono p-1 border rounded mr-2 bg-white"
                    >{`${s.w}x${s.h}`}</span>
                  ))}
                </div>
              )}
            </div>

            <div className="w-1/3 text-sm">
              <span className="block uppercase text-xs text-gray-600 font-semibold leading-none">
                Segments ({segments ? segments.length : 0})
              </span>
              {segments && segments.length > 0 && (
                <PopupWrapper
                  place="bottom center"
                  triggerType="hover"
                  hideArrow={true}
                  triggerElement={
                    <div className="flex overflow-x-auto">
                      <div className="flex bg-gray-300 rounded hover:shadow">
                        {segments.map((s) => (
                          <div
                            key={s.segmentId}
                            className="flex items-center font-mono p-1 rounded mx-1"
                          >
                            <SegmentModeIndicator
                              mode={s.mode}
                            ></SegmentModeIndicator>
                            <span className="ml-1">{s.segmentId}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                  popupElement={
                    <SegmentBasicTable segments={segments}></SegmentBasicTable>
                  }
                ></PopupWrapper>
              )}
            </div>

            <div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 border border-blue-300 rounded px-1 hover:shadow text-xs flex items-center text-blue-500"
                  onClick={() => this.handleGoToObserver(unit.gamUnitId)}
                >
                  Observer
                  <span className="ml-1">
                    <FiExternalLink></FiExternalLink>
                  </span>
                </button>

                <QsDashboardButton
                  type="UNIT"
                  data={unit}
                  networkName={unit.gamNetwork.name}
                ></QsDashboardButton>
              </div>

              <div className="text-xs text-gray-600 mt-2 leading-none">
                Data last updated at{" "}
                <span className="font-semibold">
                  {updateTime} ({updateTimeAgo})
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UnitInsightHeader;
