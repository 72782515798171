import React from "react";
import { FiInfo } from "react-icons/fi";
import { MetricDescription } from "./MetricDescription";
import BetterPopupWrapper from "../../common/BetterPopupWrapper";

class PopoverForInfoDescription extends React.PureComponent {
  render() {
    const { metricKey, position, isLarge } = this.props;

    if (!metricKey) {
      return "";
    }

    const m = MetricDescription[metricKey];

    if (!m) {
      return "";
    }

    const { title, desc, desc2, desc3 } = m;

    const contentElement = () => {
      return (
        <>
          <div
            className="text-left text-gray-700"
            style={{
              maxWidth: isLarge ? "600px" : "300px",
              padding: "8px 16px",
              fontSize: "0.85rem",
            }}
          >
            <div className="font-bold">{title}:</div>
            <div>{desc}</div>
            {desc2 && (
              <div>
                <br></br>
                {desc2}
              </div>
            )}
            {desc3 && (
              <div>
                <br></br>
                {desc3}
              </div>
            )}
          </div>
        </>
      );
    };

    return (
      <BetterPopupWrapper
        // ref={this.child}
        position={position}
        triggerType="hover"
        triggerElement={
          <div className="ml-1 text-blue-400">
            <FiInfo></FiInfo>
          </div>
        }
        contentElement={contentElement}
      ></BetterPopupWrapper>
    );
  }
}

export default PopoverForInfoDescription;
