export const snapshotSortKeyMap = {
  // GROSS_INCREASED_REVENUE: "gross_increased_revenue_twd",
  // GROSS_REVENUE_LIFT: "gross_revenue_lift",
  NET_INCREASED_REVENUE: "net_increased_revenue_twd",
  NET_REVENUE_LIFT: "net_revenue_lift",

  NET_HOLISTIC_INCREASED_REVENUE: "net_holistic_increased_revenue_twd",
  NET_HOLISTIC_REVENUE_LIFT: "net_holistic_revenue_lift",

  REV_MANAGED_SCORE: "revenue_managed_ratio",
  RAW_ELIGIBLE_REVENUE: "raw_eligible_revenue_twd"
  // HOLISTIC_INCREASED_REVENUE: "holistic_increased_revenue_twd",
  // HOLISTIC_REVENUE_LIFT: "holistic_revenue_lift",
};

export const snapshotSortKeyName = {
  // GROSS_INCREASED_REVENUE: "Gross Eligible Increased Revenue",
  // GROSS_REVENUE_LIFT: "Gross Eligible Revenue Lift",
  NET_INCREASED_REVENUE: "Net Eligible Increased Revenue",
  NET_REVENUE_LIFT: "Net Eligible Revenue Lift",

  NET_HOLISTIC_INCREASED_REVENUE: "Net Holistic Increased Revenue",
  NET_HOLISTIC_REVENUE_LIFT: "Net Holistic Revenue Lift",

  REV_MANAGED_SCORE: "Revenue Managed Score",
  RAW_ELIGIBLE_REVENUE: "Raw Eligible Revenue"

  // HOLISTIC_INCREASED_REVENUE: "Gross Holistic Increased Revenue",
  // HOLISTIC_REVENUE_LIFT: "Gross Holistic Revenue Lift",
};

export const snapshotSortByDayKeyMap = {
  TODAY: "TODAY",
  D_1: "D_1",
  PAST_3_DAYS_AVG: "PAST_3_DAYS_AVG",
  PAST_7_DAYS_AVG: "PAST_7_DAYS_AVG"
};
