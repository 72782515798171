import React from "react";
// import _ from "lodash";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import RevenueDistributionSection from "./RevenueDistributionSection";
import NumberFormat from "../../common/NumberFormat";
import { formatMoneyWithCurrency } from "./ReportsHelper";
import FadeInWrapper from "./FadeInWrapper";
import InfoDescriptionTooltip from "./InfoDescriptionTooltip";
import RightArrowLong from "../../../images/right-arrow-long.svg";
import SummaryRateGaugeChart from "./SummaryRateGaugeChart";
import PopoverForInfoDescription from "./PopoverForInfoDescription";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

function RightArrow() {
  return <img src={RightArrowLong} />;
}

class SummarySection extends React.Component {
  render() {
    const {
      summaryData,
      distributionData,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
      startDate,
      endDate,
      selectedCurrency,
    } = this.props;

    function NetRevSection() {
      return (
        <div className="p-4 border rounded shadow flex justify-center">
          <div className="flex items-end mb-2">
            <div className="font-semibold text-lg text-gray-800">
              <div className="flex items-center justify-center">
                Net Increased Revenue
                <InfoDescriptionTooltip
                  metricKey="NET_INCREASED_GOOGLE_REV"
                  place="top"
                ></InfoDescriptionTooltip>
              </div>

              <div className="text-5xl font-bold leading-none pt-1">
                <FadeInWrapper key={summaryData.netIncreasedRevenue}>
                  {formatMoneyWithCurrency({
                    currency: selectedCurrency,
                    value: summaryData.netIncreasedRevenue,
                  })}
                </FadeInWrapper>
              </div>
            </div>

            <div className="ml-3 pl-3 border-l">
              <div
                className={`font-bold flex items-center ${
                  summaryData.perceivedLift >= 0
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {summaryData.perceivedLift >= 0 ? (
                  <div className="pr-1 font-bold">
                    <FaRegArrowAltCircleUp></FaRegArrowAltCircleUp>
                  </div>
                ) : (
                  ""
                )}
                <FadeInWrapper key={summaryData.perceivedLift}>
                  {summaryData.perceivedLift >= 0 ? "+" : "-"}
                  {Math.abs(summaryData.perceivedLift)}%
                </FadeInWrapper>
              </div>
              <div className="text-xs text-gray-600 flex items-center">
                Perceived Lift
                <InfoDescriptionTooltip
                  metricKey="PERCEIVED_REVENUE_UPLIFT"
                  place="top"
                ></InfoDescriptionTooltip>
              </div>
            </div>

            <div className="ml-6 pl-3 border-l">
              <div
                className="font-bold flex items-center"
                style={{ color: "#ed8936" }} // orange
              >
                {summaryData.grossRevenueLift >= 0 ? (
                  <div className="pr-1 font-bold">
                    <FaRegArrowAltCircleUp></FaRegArrowAltCircleUp>
                  </div>
                ) : (
                  ""
                )}
                <FadeInWrapper key={summaryData.grossRevenueLift}>
                  {summaryData.grossRevenueLift >= 0 ? "+" : "-"}
                  {Math.abs(summaryData.grossRevenueLift)}%
                </FadeInWrapper>
              </div>

              <div className="text-xs text-gray-600 flex items-center">
                Managed Lift
                <InfoDescriptionTooltip
                  metricKey="MANAGED_UPLIFT"
                  place="top"
                ></InfoDescriptionTooltip>
              </div>
            </div>
          </div>
        </div>
      );
    }

    function ReqFunnelSection() {
      return (
        <div className="p-4 border rounded shadow">
          <div className="font-semibold text-lg text-gray-800 mb-4 text-center">
            Request Funnel
          </div>

          <div className="grid grid-cols-5 gap-2">
            <div className="col-span-1">
              <div className="flex flex-col items-center px-2">
                <div className="text-sm font-semibold text-gray-700 flex items-center">
                  Onboarded
                  <InfoDescriptionTooltip
                    metricKey="ONBOARDED_REQ"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-lg font-semibold text-gray-900">
                  <FadeInWrapper key={summaryData.onboardedRequests}>
                    <NumberFormat
                      value={summaryData.onboardedRequests}
                      format="0,0.00a"
                    ></NumberFormat>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center text-gray-600">
              <RightArrow></RightArrow>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col items-center px-2">
                <div className="text-sm font-semibold text-gray-700 flex items-center">
                  Eligible
                  <InfoDescriptionTooltip
                    metricKey="ELIGIBLE_REQ"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-lg font-semibold text-gray-900">
                  <FadeInWrapper key={summaryData.remnentRequests}>
                    <NumberFormat
                      value={summaryData.remnentRequests}
                      format="0,0.00a"
                    ></NumberFormat>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center text-gray-600">
              <RightArrow></RightArrow>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col items-center px-2">
                <div className="text-sm font-semibold text-gray-700 flex items-center">
                  Managed
                  <InfoDescriptionTooltip
                    metricKey="MANAGED_REQ"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-lg font-semibold text-gray-900">
                  <FadeInWrapper key={summaryData.boostingRequests}>
                    <NumberFormat
                      value={summaryData.boostingRequests}
                      format="0,0.00a"
                    ></NumberFormat>
                  </FadeInWrapper>
                </div>
              </div>
            </div>

            <div className="col-span-1"></div>

            <div className="col-span-1 row-start-2 col-start-2">
              <div
                className="flex flex-col items-center py-2"
                style={{ marginTop: "-24px" }}
              >
                <div className="text-sm text-gray-800">
                  <FadeInWrapper key={summaryData.remnentRate}>
                    {/* <NumberFormat
                      value={summaryData.remnentRate}
                      format="0,0.00"
                      postfix="%"
                    ></NumberFormat> */}
                    <SummaryRateGaugeChart
                      value={summaryData.remnentRate}
                    ></SummaryRateGaugeChart>
                  </FadeInWrapper>
                </div>

                <div className="text-xs text-gray-600 text-center flex items-center">
                  <span className="-ml-3">Eligible Rate</span>
                  <InfoDescriptionTooltip
                    metricKey="REQ_ELIGIBLE_RATE"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
              </div>
            </div>

            <div className="col-span-1 row-start-2 col-start-4">
              <div
                className="flex flex-col items-center py-2"
                style={{ marginTop: "-24px" }}
              >
                <div className="text-sm text-gray-800">
                  <FadeInWrapper key={summaryData.boostingRate}>
                    {/* <NumberFormat
                      value={summaryData.boostingRate}
                      format="0,0.00"
                      postfix="%"
                    ></NumberFormat> */}

                    <SummaryRateGaugeChart
                      value={summaryData.boostingRate}
                    ></SummaryRateGaugeChart>
                  </FadeInWrapper>
                </div>
                <div className="text-xs text-gray-600 text-center flex items-center">
                  <span className="-ml-3">Managed Rate</span>
                  <InfoDescriptionTooltip
                    metricKey="REQ_MANAGED_RATE"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={fixedWidthStyle}>
        <div className="text-2xl font-bold mb-4">Summary</div>
        {/* 
        <div className="grid grid-rows-3 grid-cols-3 gap-4">
          <div className="row-start-1 row-end-3 col-start-1 col-end-3">
            <NetRevSection></NetRevSection>
          </div>
          <div className="row-start-2 row-end-4 col-start-1 col-end-3">
            <ReqFunnelSection></ReqFunnelSection>
          </div>

          <div className="row-start-1 row-end-4 col-start-3">
            <div className="border shadow rounded">
              <RevenueDistributionSection
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetId={selectedYieldSetId}
                startDate={startDate}
                endDate={endDate}
                distributionData={distributionData}
                selectedCurrency={selectedCurrency}
              ></RevenueDistributionSection>
            </div>
          </div>
        </div> */}

        <div className="flex">
          <div className="w-3/5 mr-8">
            <div className="mb-6">
              <NetRevSection></NetRevSection>
            </div>
            <div>
              <ReqFunnelSection></ReqFunnelSection>
            </div>
          </div>

          <div className="w-2/5">
            <div className="border shadow rounded">
              <RevenueDistributionSection
                selectedPubId={selectedPubId}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetId={selectedYieldSetId}
                startDate={startDate}
                endDate={endDate}
                distributionData={distributionData}
                selectedCurrency={selectedCurrency}
              ></RevenueDistributionSection>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummarySection;
