import React from "react";
import _ from "lodash";
import NumberFormat from "../common/NumberFormat";
import ReactTooltip from "react-tooltip";
import { FiExternalLink } from "react-icons/fi";
import QsDashboardButton from "./QsDashboardButton";
import UnitStatus from "../common/UnitStatus";
import UnitMode from "../common/UnitMode";
import { unitPilotMode } from "../../constants/Unit";
import AutoPilotConfigEditButton from "./AutoPilotConfigEditButton";
import DateTimeFormatter from "../common/DateTimeFormatter";
import ClickToCopyWrapper from "../common/ClickToCopyWrapper";
// import PinUnitButton from "./PinUnitButton";

const demandChannelsMap = {
  AD_EXCHANGE: "Adx",
  "Ad Exchange": "Adx",
  ADSENSE: "Ads",
  // no need to show SMART, Smart Bidding
  // BIDDING: "SB",
  BULK: "Bulk",
  "Exchange Bidding": "EB",
  HOUSE: "House",
  NETWORK: "Network",
  PREFERRED_DEAL: "Preferred Deal",
  PRICE_PRIORITY: "PP",
  // SMART: "SMART",
  SPONSORSHIP: "Sponsor",
  STANDARD: "Standard",
};

const demandTypePriorityMap = {
  Sponsor: 1,
  Standard: 2,
  PD: 3,
  Adx: 4,
  Ads: 5,
  OB: 6,
  EB: 7,
  SB: 8,
  PP: 9,
  Bulk: 10,
  House: 12,
  Network: 11,
};

class UnitMetricPerDay extends React.Component {
  render() {
    const { unit, day } = this.props;

    return (
      <div>
        <div
          className={`text-xs font-semibold text-gray-700 mt-2 mb-1 text-center ${
            day === 1 ? "bg-gray-400" : "bg-gray-200"
          }`}
        >
          {day === 0 ? "Today" : `D-${day}`}
        </div>
        <div
          className={`flex justify-between text-right ${
            day === 1 ? "font-semibold" : ""
          }`}
        >
          <div className="flex flex-col">
            <div className="text-xs leading-snug">Raw Rev Ratio</div>
            <div className="font-bold">
              <NumberFormat
                value={unit.raw_revenue_ratio * 100}
                postfix="%"
              ></NumberFormat>
            </div>
          </div>

          <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">Net Eligible</div>
            <div className={unit.net_revenue_lift < 0 ? "text-red-600" : ""}>
              <NumberFormat
                value={unit.net_revenue_lift * 100}
                postfix="%"
              ></NumberFormat>
            </div>
            <div
              className={`leading-none font-light ${
                unit.net_revenue_trans < 0 ? "text-red-600" : ""
              }`}
            >
              <small>
                <NumberFormat
                  value={unit.net_revenue_trans}
                  prefix="$"
                ></NumberFormat>
              </small>
            </div>
          </div>

          <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">Net All Hol</div>
            <div
              className={
                unit.net_all_holistic_revenue_lift < 0 ? "text-red-600" : ""
              }
            >
              <NumberFormat
                value={unit.net_all_holistic_revenue_lift * 100}
                postfix="%"
              ></NumberFormat>
            </div>
            <div
              className={`leading-none font-light ${
                unit.net_all_holistic_increased_revenue_trans < 0
                  ? "text-red-600"
                  : ""
              }`}
            >
              <small>
                <NumberFormat
                  value={unit.net_all_holistic_increased_revenue_trans}
                  prefix="$"
                ></NumberFormat>
              </small>
            </div>
          </div>

          {/* <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">Net Prog Hol</div>
            <div
              className={
                unit.net_holistic_revenue_lift < 0 ? "text-red-600" : ""
              }
            >
              <NumberFormat
                value={unit.net_holistic_revenue_lift * 100}
                postfix="%"
              ></NumberFormat>
            </div>
            <div
              className={`leading-none font-light ${
                unit.net_holistic_increased_revenue_trans < 0
                  ? "text-red-600"
                  : ""
              }`}
            >
              <small>
                <NumberFormat
                  value={unit.net_holistic_increased_revenue_trans}
                  prefix="$"
                ></NumberFormat>
              </small>
            </div>
          </div> */}

          <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">Raw Elig Rev</div>
            <NumberFormat
              value={unit.raw_eligible_revenue_trans}
              format="$0,0.00a"
            ></NumberFormat>

            <div className="leading-none font-light text-xs">
              <small>
                Req:{" "}
                <NumberFormat
                  value={unit.client_request}
                  format="0,0.00a"
                ></NumberFormat>
              </small>
            </div>
          </div>

          <div className="flex flex-col justify-between text-right text-xs">
            <div className="leading-snug">MScore</div>
            <div>
              <small>Rev: </small>
              <NumberFormat
                value={unit.managed_revenue_ratio * 100}
                postfix="%"
                format="0,0"
              ></NumberFormat>
            </div>
            <div
              className={`leading-none font-light ${
                unit.managed_impression_ratio < 0 ? "text-red-600" : ""
              }`}
            >
              <small>
                <span>Imp: </span>
                <NumberFormat
                  value={unit.managed_impression_ratio * 100}
                  postfix="%"
                  format="0,0"
                ></NumberFormat>
              </small>
            </div>
          </div>

          <div className="flex flex-col justify-between text-right text-xs">
            <div
              className="leading-snug"
              title="AV Viewable Imp / AV Measurable Imp"
            >
              ViewImp
            </div>
            <div>
              <small>AdX: </small>
              <NumberFormat
                value={unit.adx_viewability}
                postfix="%"
                format="0,0"
              ></NumberFormat>
            </div>
            <div className="leading-none font-light">
              <small>
                <span>Total: </span>
                <NumberFormat
                  value={unit.total_viewability}
                  postfix="%"
                  format="0,0"
                ></NumberFormat>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AdUnitBasicCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleGoToObserver = this.handleGoToObserver.bind(this);
    this.handleGoToUnitInsight = this.handleGoToUnitInsight.bind(this);
    this.handleGoToUnitAPConfigPage = this.handleGoToUnitAPConfigPage.bind(
      this
    );
  }

  handleGoToObserver(unitId) {
    window.open(`${window.location.origin}/#/observer/${unitId}`);
  }

  handleGoToUnitInsight(unitId) {
    window.open(`${window.location.origin}/#/unit-insight/${unitId}`);
  }

  handleGoToUnitAPConfigPage(unitId) {
    window.open(
      `${window.location.origin}/#/control-center/unit/${unitId}/auto-pilot/config`
    );
  }

  render() {
    const {
      unit,
      networkName,
      type,
      showFullWidthCard,
      isCompactMode,
    } = this.props;

    const demandTypes = _(unit.demand_types.split(","))
      .map((t) => {
        return demandChannelsMap[t];
      })
      .uniq()
      .compact()
      .orderBy((t) => {
        return demandTypePriorityMap[t];
      })
      .value();

    if (type === "NOT_ONBOARDED_UNITS") {
      return (
        <UnitCardNotOnboarded
          unit={unit}
          demandTypes={demandTypes}
          showFullWidthCard={showFullWidthCard}
        ></UnitCardNotOnboarded>
      );
    } else {
      return (
        <UnitCardRevenueDriver
          unit={unit}
          type={type}
          demandTypes={demandTypes}
          networkName={networkName}
          handleGoToObserver={this.handleGoToObserver}
          handleGoToUnitInsight={this.handleGoToUnitInsight}
          handleGoToUnitAPConfigPage={this.handleGoToUnitAPConfigPage}
          showFullWidthCard={showFullWidthCard}
          isCompactMode={isCompactMode}
        ></UnitCardRevenueDriver>
      );
    }
  }
}

class RequestTypesSection extends React.PureComponent {
  render() {
    const { requestTypes } = this.props;
    return (
      <div className="flex my-2">
        {requestTypes.split(",").map((d) => {
          return (
            <div
              key={d}
              className="text-xs px-2 mr-2 text-gray-600 bg-indigo-100 rounded"
              title={d}
            >
              {d === "Google Publisher Tag" ? "GPT" : d}
            </div>
          );
        })}
      </div>
    );
  }
}

class InspectionSection extends React.PureComponent {
  render() {
    const { forceInspection } = this.props;
    if (forceInspection) {
      return (
        <div className="text-xs px-1 border-l" title="Force Inspection">
          {forceInspection && (
            <span className="text-orange-100 bg-orange-400 rounded font-bold px-2">
              INSP
            </span>
          )}
        </div>
      );
    } else {
      return "";
    }
  }
}

class BmSection extends React.PureComponent {
  render() {
    const { unitId, bpm, enableAbuse, bmAbuseSizes } = this.props;
    /*
    bmAbuseSizes=[
      [
        "fluid",
        [
          1,
          1
        ]
      ]
    ]
    */
    let abuseSizesString = "None";
    if (bmAbuseSizes) {
      let sizes = bmAbuseSizes[0];
      abuseSizesString = sizes
        .map((s) => {
          if (typeof s === "string") {
            return s;
          } else {
            return s.join("x");
          }
        })
        .join(", ");
    }

    return (
      <div
        className="text-xs px-1 border-l"
        title="Benchmark Performance Multiplier"
      >
        BPM:{" "}
        {bpm < 1 ? (
          <>
            <span
              className="text-orange-600 font-bold"
              data-tip
              data-for={`bpm-label-${unitId}`}
            >
              <NumberFormat value={bpm} format="0,0%"></NumberFormat>
            </span>
            <ReactTooltip
              id={`bpm-label-${unitId}`}
              type="dark"
              effect="solid"
              place="top"
            >
              Enable benchmark tfcd abuse:{" "}
              <b>{enableAbuse ? "true" : "false"}</b>
              <br></br>
              Benchmark Abuse Sizes: <b>{abuseSizesString}</b>
            </ReactTooltip>
          </>
        ) : (
          <span className="text-gray-600">
            <NumberFormat value={bpm} format="0,0%"></NumberFormat>
          </span>
        )}
      </div>
    );
  }
}

class BmSizesSection extends React.PureComponent {
  transformBmSizes(bmSizes) {
    return _.isArray(bmSizes)
      ? _.map(bmSizes, (sizes) => {
          return _.isArray(sizes) ? sizes.join("x") : sizes;
        }).join(", ")
      : bmSizes;
  }

  transformBmRandomSizesWithLineBreak(value) {
    // const testValue = [
    //   [
    //     [[160, 600], "fluid"],
    //     [
    //       [160, 600],
    //       [1, 1],
    //       [2, 2]
    //     ],
    //     [[160, 600]],
    //     [[152, 652]]
    //   ]
    // ];

    const sizesSets = _.map(value, (sizesSet, i) => {
      if (_.isArray(sizesSet)) {
        const sizes = _.map(sizesSet, (size) => {
          if (_.isArray(size)) {
            return size.join("x");
          }
          return size;
        });

        return <div key={i}>{sizes.join(",")}</div>;
      }
      return <div key={i}>{sizesSet}</div>;
    });

    return sizesSets;
  }

  transformBmRandomSizes(value) {
    // const testValue = [
    //   [
    //     [[160, 600], "fluid"],
    //     [
    //       [160, 600],
    //       [1, 1],
    //       [2, 2]
    //     ],
    //     [[160, 600]],
    //     [[152, 652]]
    //   ]
    // ];

    const layers = _.map(value, (layerSizes) => {
      if (_.isArray(layerSizes)) {
        const sizes = _.map(layerSizes, (sizes) => {
          if (_.isArray(sizes)) {
            const sizesSet = _.map(sizes, (size) => {
              return _.isArray(size) ? size.join("x") : size;
            });
            return `(${sizesSet.length}) [${sizesSet.join(", ")}]`;
          }
          return sizes;
        });

        return sizes.join(", ");
      }
      return layerSizes;
    });

    return layers;
  }

  renderByUnitConfig() {
    const { bmRandomSizes, unitId } = this.props;

    if (bmRandomSizes && !_.isEmpty(bmRandomSizes)) {
      const sizesString = this.transformBmRandomSizesWithLineBreak(
        bmRandomSizes
      );

      return (
        <div className="text-xs px-1 border-l">
          <span
            data-tip
            data-for={`bmsizes-label-${unitId}`}
            className="underline font-semibold"
          >
            bmRandomSizes ({bmRandomSizes.length})
          </span>

          <ReactTooltip
            id={`bmsizes-label-${unitId}`}
            type="dark"
            effect="solid"
            place="top"
          >
            {sizesString}
          </ReactTooltip>
        </div>
      );
    }

    return "";
    // return <div className="text-xs px-1 border-l">None</div>;
  }

  render() {
    return this.renderByUnitConfig();
  }
}

class DebugModeSection extends React.PureComponent {
  render() {
    const { enableDispatcherDebug } = this.props;
    return (
      <div className="text-xs px-1">
        {enableDispatcherDebug && (
          <span className="text-pink-100 bg-pink-500 rounded font-bold px-2">
            DEBUG
          </span>
        )}
      </div>
    );
  }
}

class MaxTrafficRatioSection extends React.PureComponent {
  render() {
    const { maxTrafficRatio, minTrafficRatio } = this.props;
    return (
      <div className="text-xs px-1 border-l">
        Traffic:{" "}
        <span className="text-gray-600">
          <NumberFormat value={minTrafficRatio} format="0,0%"></NumberFormat>
        </span>
        <span className="px-1">-</span>
        <span className="text-gray-600">
          <NumberFormat value={maxTrafficRatio} format="0,0%"></NumberFormat>
        </span>
      </div>
    );
  }
}

class AdPodsSection extends React.PureComponent {
  render() {
    const { enableAdPods } = this.props;

    return (
      <>
        {enableAdPods ? (
          <div className="text-xs px-1 border-l">
            <span className="text-purple-600 font-bold">ADPODS</span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

class UnitChangeTimeSection extends React.PureComponent {
  render() {
    const { unit, type } = this.props;

    return (
      <>
        <div
          data-tip
          data-for={`unit-changed-at-${unit.gam_unit_id}`}
          className="text-gray-700 whitespace-no-wrap text-xs px-1 border-l"
        >
          {type === "NEWLY_ONBOARDED_UNITS" ? (
            <>
              Onboarded{" "}
              <span className="text-gray-900">
                <DateTimeFormatter
                  fromNowOnly={true}
                  datetime={unit.created_at}
                ></DateTimeFormatter>
              </span>
            </>
          ) : (
            <>
              Changes made{" "}
              <span className="text-gray-900">
                <DateTimeFormatter
                  fromNowOnly={true}
                  datetime={unit.last_changed_at}
                ></DateTimeFormatter>
              </span>
            </>
          )}
        </div>

        <ReactTooltip
          id={`unit-changed-at-${unit.gam_unit_id}`}
          type="dark"
          effect="solid"
          place="top"
        >
          <table>
            <tbody>
              <tr>
                <td className="text-right pr-2">Created At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.created_at}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="text-right pr-2">Updated At</td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.updated_at}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>

              <tr>
                <td className="text-right pr-2">
                  Auto Pilot Config Updated At
                </td>
                <td>
                  <b>
                    <DateTimeFormatter
                      format="YYYY-MM-DD HH:mm"
                      datetime={unit.config_updated_at}
                    ></DateTimeFormatter>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </ReactTooltip>
      </>
    );
  }
}

class DemandTypesSection extends React.PureComponent {
  render() {
    const { demandTypes } = this.props;

    return (
      <>
        {demandTypes.map((d) => {
          return (
            <div
              key={d}
              className="text-xs px-2 mr-2 text-gray-600 bg-gray-200 rounded"
            >
              {d}
            </div>
          );
        })}
      </>
    );
  }
}

class UnitCardRevenueDriver extends React.PureComponent {
  render() {
    const {
      unit,
      type,
      demandTypes,
      networkName,
      handleGoToObserver,
      handleGoToUnitInsight,
      handleGoToUnitAPConfigPage,
      showFullWidthCard,
      isCompactMode,
    } = this.props;

    const unitD1 = unit.last_day_metrics;
    const unitD2 = unit.last_2_day_metrics;
    const unitD3 = unit.last_3_day_metrics;

    const unitMetricDaysData = isCompactMode
      ? [unit, unitD1]
      : [unit, unitD1, unitD2, unitD3];

    return (
      <div>
        <div
          key={unit.id}
          className="text-sm p-2 bg-white text-gray-800 border border-gray-300"
          style={{ width: showFullWidthCard ? "100%" : "36rem" }}
          // style={{ width: "22rem" }}
        >
          <div className="flex justify-between -ml-2 -mr-2 -mt-2 px-2 py-1 bg-gray-100 text-gray-700">
            <div className="w-4/5 flex items-center">
              <div className="mr-1 text-gray-800 font-semibold">
                {unit.gam_unit_id}
              </div>
              <div className="truncate text-gray-800" title={unit.name}>
                {unit.name}
              </div>
            </div>
            <div className="flex items-center justify-end text-sm w-1/5">
              <div className="px-1">
                <DebugModeSection
                  enableDispatcherDebug={
                    unit.autopilot_brief.enable_dispatcher_debugging
                  }
                ></DebugModeSection>
              </div>
              <div className="px-1">
                <UnitStatus unit={unit}></UnitStatus>
              </div>
              <div className="px-1">
                <UnitMode unit={unit}></UnitMode>
              </div>
              <div className="pl-1">
                <UnitUpdateMode unit={unit}></UnitUpdateMode>
              </div>
            </div>
          </div>

          <div className="-ml-2 -mr-2 -mt-2 px-2 py-2 bg-gray-100 text-gray-700">
            <UnitMetadata unit={unit}></UnitMetadata>
          </div>

          <div className="flex justify-between items-center border-b whitespace-no-wrap">
            <div
              className="flex overflow-x-auto w-full"
              style={{ minWidth: "48px" }}
            >
              <RequestTypesSection
                requestTypes={unit.request_types}
              ></RequestTypesSection>
            </div>

            <div className="flex">
              <div>
                <div className="text-xs text-gray-600 px-1 border-l">
                  <span className="text-gray-800">YS: </span>
                  <span title="YieldSet ID">{unit.ys_id} </span>
                  <span title="YieldSet Name">{unit.ys_name}</span>
                </div>
              </div>
              <div>
                <AdPodsSection
                  enableAdPods={unit.autopilot_brief.enable_ad_pods}
                ></AdPodsSection>
              </div>
              <div>
                <InspectionSection
                  unitId={unit.gam_unit_id}
                  forceInspection={unit.autopilot_brief.force_inspection}
                ></InspectionSection>
              </div>
              <div>
                <BmSection
                  unitId={unit.gam_unit_id}
                  bpm={unit.autopilot_brief.benchmark_performance_multiplier}
                  enableAbuse={unit.autopilot_brief.enable_benchmark_tfcd_abuse}
                  bmAbuseSizes={unit.autopilot_brief.benchmark_abuse_sizes}
                ></BmSection>
              </div>
              {/* Deprecated metric */}
              {/* <div>
                <BmSizesSection
                  unitId={unit.gam_unit_id}
                  bmRandomSizes={unit.autopilot_brief.benchmark_random_sizes}
                ></BmSizesSection>
              </div> */}
              <div>
                <MaxTrafficRatioSection
                  minTrafficRatio={unit.autopilot_brief.minimum_traffic_ratio}
                  maxTrafficRatio={unit.autopilot_brief.maximum_traffic_ratio}
                ></MaxTrafficRatioSection>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex mt-2 mb-1 whitespace-no-wrap overflow-x-auto w-full">
              <DemandTypesSection
                demandTypes={demandTypes}
              ></DemandTypesSection>
            </div>
            <div className="flex mt-2 mb-1">
              <UnitChangeTimeSection
                unit={unit}
                type={type}
              ></UnitChangeTimeSection>
            </div>
          </div>

          {unitMetricDaysData.map((u, i) => {
            return (
              <React.Fragment key={i}>
                {u && <UnitMetricPerDay unit={u} day={i}></UnitMetricPerDay>}
              </React.Fragment>
            );
          })}

          <div className="flex justify-between mt-4 pt-2 border-t">
            <button
              type="button"
              className="mr-2 border border-blue-300 rounded px-1 hover:shadow text-xs flex items-center text-blue-500"
              onClick={() => handleGoToObserver(unit.gam_unit_id)}
            >
              Observer
              <span className="ml-1">
                <FiExternalLink></FiExternalLink>
              </span>
            </button>

            <button
              type="button"
              className="mr-2 border border-blue-300 rounded px-1 hover:shadow text-xs flex items-center text-blue-500"
              onClick={() => handleGoToUnitInsight(unit.gam_unit_id)}
            >
              Insight
              <span className="ml-1">
                <FiExternalLink></FiExternalLink>
              </span>
            </button>

            <QsDashboardButton
              type="UNIT"
              data={unit}
              networkName={networkName}
            ></QsDashboardButton>

            <AutoPilotConfigEditButton
              buttonText={"Edit Auto Pilot Config"}
              goToConfigControlPage={() =>
                handleGoToUnitAPConfigPage(unit.gam_unit_id)
              }
            ></AutoPilotConfigEditButton>
          </div>
        </div>
      </div>
    );
  }
}

class UnitCardNotOnboarded extends React.PureComponent {
  render() {
    const { unit, demandTypes, showFullWidthCard } = this.props;
    return (
      <>
        <div
          key={unit.ext_gam_unit_id}
          className="text-sm p-2 bg-white text-gray-800 border border-gray-300"
          style={{ width: showFullWidthCard ? "100%" : "20rem" }}
        >
          <div className="-ml-2 -mr-2 -mt-2 px-2 py-1 bg-gray-100 text-gray-700">
            <div className="mr-1 text-gray-600">
              Ext Gam Unit ID: {unit.ext_gam_unit_id}
            </div>
          </div>

          <div className="flex justify-between items-center border-b whitespace-no-wrap">
            <div className="flex overflow-x-auto w-full">
              <RequestTypesSection
                requestTypes={unit.request_types}
              ></RequestTypesSection>
            </div>
          </div>

          <div className="flex justify-between overflow-x-auto w-full border-b">
            {demandTypes && demandTypes.length > 0 ? (
              <div className="flex my-2 whitespace-no-wrap">
                <DemandTypesSection
                  demandTypes={demandTypes}
                ></DemandTypesSection>
              </div>
            ) : (
              <div className="h-8 mb-1"></div>
            )}
          </div>

          <div className="flex justify-between text-right mt-2">
            <div>
              <div className="text-xs leading-snug">Raw Rev Ratio</div>
              <div className="font-bold">
                <NumberFormat
                  value={unit.raw_revenue_ratio * 100}
                  postfix="%"
                ></NumberFormat>
              </div>
            </div>

            <div>
              <div className="text-xs leading-snug">Client Req</div>
              <div>
                <NumberFormat
                  value={unit.client_request}
                  format="0,0.00a"
                ></NumberFormat>
              </div>
            </div>

            <div>
              <div className="flex flex-col justify-between text-right text-sm">
                <div className="leading-snug text-xs">Raw Eligible Rev</div>
                <div
                  className={`font-semibold ${
                    unit.raw_eligible_revenue_trans < 0 ? "text-red-600" : ""
                  }`}
                >
                  <NumberFormat
                    value={unit.raw_eligible_revenue_trans}
                    prefix="$"
                  ></NumberFormat>
                </div>
              </div>

              <div className="flex flex-col justify-between text-right text-sm mt-2">
                <div className="leading-snug text-xs">Raw Eligible Imp</div>

                <div
                  className={
                    unit.raw_eligible_impression < 0 ? "text-red-600" : ""
                  }
                >
                  <NumberFormat
                    value={unit.raw_eligible_impression}
                    format="0,0"
                  ></NumberFormat>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class UnitMetadata extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { unit } = this.props;

    return (
      <>
        <div className="flex justify-between text-xs mt-1 text-gray-600">
          <div className="flex font-mono" title="Ext Unit ID">
            {unit.ext_gam_unit_id}
            <ClickToCopyWrapper
              copyText={unit.ext_gam_unit_id}
            ></ClickToCopyWrapper>
          </div>

          <span title="Ext Unit Path">{unit.ext_unit_path}</span>
        </div>
      </>
    );
  }
}

class UnitUpdateMode extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { unit } = this.props;

    // AUTO, MANUAL, SYSTEM_DEFAULT
    // ALL Units are AUTO PILOT NOW
    const pilotMode = unitPilotMode.AUTO;

    return (
      <div className="text-xs font-semibold">
        {pilotMode === unitPilotMode.AUTO && (
          <span className="text-teal-600">AUTO</span>
        )}

        {pilotMode === unitPilotMode.MANUAL && (
          <span className="text-indigo-700">MANUAL</span>
        )}

        {pilotMode === unitPilotMode.SYSTEM_DEFAULT && <span>DEFAULT</span>}
      </div>
    );
  }
}

export default AdUnitBasicCard;
