const OBSERVER_API = process.env.REACT_APP_OBSERVER_API_ENDPOINT;

export async function isUserAuthenticated() {
  // return true;
  const response = await fetch(`${OBSERVER_API}/me`, {
    credentials: "include",
  })
    .then(function (response) {
      return response.ok;
    })
    .catch(function (error) {
      throw error;
    });

  return await response;
}
