import React from "react";
import _ from "lodash";
// import moment from "moment-timezone";
import LoadingUI from "../../common/LoadingUI";
import YbMockupHomeTopFilterHeader from "./TopFilterHeader";
import YbMockupHomeInventorySection from "./InventorySection";
import SummarySection from "./SummarySection";
import TrendSection from "./TrendSection";
import { getHomeDashboardData } from "../../../helpers/Api";
// import PublisherSelector from "./PublisherSelector";
import { DATE_RANGE_TYPE, DATE_FORMAT, getDateRangeByType } from "./DateHelper";
import {
  transformHomeDashboardData,
  calculateInventoryData,
  calculateSummaryData,
  calculateDistributionData,
  calculateTrendData,
} from "./ReportsHelper";
import PopoverForInfoDescription from "./PopoverForInfoDescription";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

function _convertDateToYMD(date) {
  const dd = new Date(date);
  let d = dd.getDate();
  let m = dd.getMonth() + 1; // Month from 0 to 11
  let y = dd.getFullYear();
  let finalString = `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
  return finalString;
}

class HomeDashboardViewer extends React.Component {
  constructor(props) {
    super(props);

    // default to "this month"
    const { startDate, endDate } = getDateRangeByType(DATE_RANGE_TYPE.DEFAULT);

    this.state = {
      isLoading: false,
      errMsg: null,

      homeDashboardData: null,

      // YEAHHHHHHHH
      selectedPubId: -1, // Default: All publishers
      filteredNetworks: null,
      selectedNetworkId: -1, // Default: All networks
      selectedYieldSetId: -1, // All YieldSets
      selectedCurrency: "USD",

      // date filter!!!!
      startDate,
      endDate,

      // inventoryData
      // summaryData
      // distributionData
      // trendData
      // currencies
      // exchangeRates
    };

    this.handlePublisherChanged = this.handlePublisherChanged.bind(this);
    this.handleInventoryFilterChanged =
      this.handleInventoryFilterChanged.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  async componentDidMount() {
    document.title = `Yieldbooster Console Mockup`;
    this.setState({ isLoading: true });
    const pubId = _.get(this.props, "match.params.pubId");

    const isReseller = _.startsWith(
      _.get(this.props, "location.pathname"),
      "/playground/console-overview-mockup-reseller"
    );

    try {
      const homeDashboardData = transformHomeDashboardData(
        await getHomeDashboardData(pubId)
      );

      if (!homeDashboardData) {
        throw new Error("No Data");
      }

      const { publishers, gamNetworks } = homeDashboardData;

      // Preselect the only publisher, otherwise "All"
      const selectedPubId =
        publishers.length === 1 ? _.get(publishers, [0, "id"], -1) : -1;

      // For Reseller
      let filteredNetworks = gamNetworks;
      if (selectedPubId !== -1) {
        filteredNetworks = _.filter(gamNetworks, { pubId: selectedPubId });
      }

      const currencies = _.keys(homeDashboardData.exchangeRates);
      const exchangeRates =
        homeDashboardData.exchangeRates[this.state.selectedCurrency];

      // Preselect the only network, otherwise "All"
      const selectedNetworkId =
        gamNetworks.length === 1 ? _.get(gamNetworks, [0, "id"], -1) : -1;

      this.setState({
        homeDashboardData,
        currencies,
        exchangeRates,

        isReseller,
        publishers,
        selectedPubId,
        // filteredNetworks: gamNetworks,
        filteredNetworks,
        selectedNetworkId,
        selectedYieldSetId: -1, // All YieldSets
        inventoryData: calculateInventoryData({
          homeDashboardData,
          selectedPubId: this.state.selectedPubId,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
        }),
        summaryData: calculateSummaryData({
          homeDashboardData,
          selectedPubId: this.state.selectedPubId,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          exchangeRates,
        }),
        distributionData: calculateDistributionData({
          homeDashboardData,
          selectedPubId: this.state.selectedPubId,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          exchangeRates,
        }),
        trendData: calculateTrendData({
          homeDashboardData,
          selectedPubId: this.state.selectedPubId,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          exchangeRates,
        }),
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err });
    }

    this.setState({ isLoading: false });
  }

  handlePublisherChanged(pubId) {
    this.setState({ selectedPubId: pubId });
    console.log("Publisher changed: ", pubId);
  }

  handleInventoryFilterChanged({
    selectedPubId,
    selectedNetworkId,
    selectedYieldSetId,
  }) {
    console.log(
      "Inventory Filter changed: ",
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId
    );
    this.setState({
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
      inventoryData: calculateInventoryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    // Important! Convert a date object (with timezone) to just YYYY-MM-DD format
    var sd = _convertDateToYMD(startDate);
    var ed = _convertDateToYMD(endDate);

    console.log("Date Range Filter changed: ", sd, ed);
    this.setState({
      startDate: sd,
      endDate: ed,
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleCurrencyChanged(currency) {
    console.log("Currency changed: ", currency);
    const {
      homeDashboardData,
      startDate,
      endDate,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
    } = this.state;
    const exchangeRates = homeDashboardData.exchangeRates[currency];
    this.setState({
      selectedCurrency: currency,
      exchangeRates,
      startDate,
      endDate,
      summaryData: calculateSummaryData({
        homeDashboardData: homeDashboardData,
        selectedPubId,
        selectedNetworkId: selectedNetworkId,
        selectedYieldSetId: selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
    });
  }

  render() {
    const {
      isLoading,
      errMsg,
      homeDashboardData,

      isReseller,
      selectedPubId,
      publishers,
      filteredNetworks,
      selectedNetworkId,
      selectedYieldSetId,
      inventoryData,
      summaryData,
      startDate,
      endDate,
      distributionData,
      trendData,
      currencies,
      selectedCurrency,
    } = this.state;

    if (isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (errMsg) {
      return <div className="text-white">{errMsg}</div>;
    }

    if (!homeDashboardData) {
      return "No Data";
    }

    return (
      <>
        <div className="bg-white px-12 border-bottom border">
          <h1 className="pt-8 pb-2" style={fixedWidthStyle}>
            <div className="text-base leading-none font-bold text-gray-600">
              Yieldbooster Console
            </div>
            <div className="flex items-center">
              <div className="font-extrabold text-gray-900 text-4xl mr-4 flex items-center">
                Home Dashboard
                <PopoverForInfoDescription></PopoverForInfoDescription>
              </div>
              {/* 
              <PublisherSelector
                publishers={homeDashboardData.publishers}
                selectedPubId={selectedPubId}
                handlePublisherChanged={this.handlePublisherChanged}
              ></PublisherSelector> */}
            </div>
          </h1>
        </div>
        <div>
          <div className="sticky top-0 z-40 shadow">
            <YbMockupHomeTopFilterHeader
              isReseller={isReseller}
              selectedPubId={selectedPubId}
              publishers={publishers}
              networks={filteredNetworks}
              selectedNetworkId={selectedNetworkId}
              selectedYieldSetId={selectedYieldSetId}
              yieldSets={homeDashboardData.yieldSets}
              handleInventoryFilterChanged={this.handleInventoryFilterChanged}
              currencies={currencies}
              selectedCurrency={selectedCurrency}
              handleCurrencyChanged={this.handleCurrencyChanged}
              startDate={startDate}
              endDate={endDate}
              handleDateRangeFilterChanged={this.handleDateRangeFilterChanged}
            ></YbMockupHomeTopFilterHeader>
          </div>

          <div className="bg-white px-12 py-6">
            <div>
              <YbMockupHomeInventorySection
                updatedTime={homeDashboardData.updatedTime}
                inventoryData={inventoryData}
                selectedYieldSetId={selectedYieldSetId}
              ></YbMockupHomeInventorySection>
            </div>

            <div className="my-8">
              <SummarySection
                selectedPubId={selectedPubId}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetId={selectedYieldSetId}
                startDate={startDate}
                endDate={endDate}
                summaryData={summaryData}
                distributionData={distributionData}
                selectedCurrency={selectedCurrency}
              ></SummarySection>
            </div>

            <div>
              <TrendSection
                selectedPubId={selectedPubId}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetId={selectedYieldSetId}
                startDate={startDate}
                endDate={endDate}
                reports={trendData}
                summaryData={summaryData}
                selectedCurrency={selectedCurrency}
              ></TrendSection>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeDashboardViewer;
